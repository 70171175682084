div.center {
  font-size: inherit;
  position: relative;
  color: transparent;
}

div.spinner {
  z-index: 1;
  position: absolute;
  width: 32px;
  height: 32px;
  display: inline-block;
  background: 'transparent';
  padding: 10px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

div.spinner div {
  width: 5%;
  height: 12%;
  background: #fff;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-animation: fade 1s linear infinite;
  animation: fade 1s linear infinite;
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

div.spinner div.bar1 {
  -webkit-transform: rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: -0.333s;
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: -0.333s;
}

div.spinner div.bar2 {
  -webkit-transform: rotate(40deg) translate(0, -130%);
  -webkit-animation-delay: -0.9167s;
  transform: rotate(40deg) translate(0, -130%);
  animation-delay: -0.9167s;
}

div.spinner div.bar3 {
  -webkit-transform: rotate(120deg) translate(0, -130%);
  -webkit-animation-delay: -0.833s;
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.833s;
}
div.spinner div.bar4 {
  -webkit-transform: rotate(160deg) translate(0, -130%);
  -webkit-animation-delay: -0.7497s;
  transform: rotate(160deg) translate(0, -130%);
  animation-delay: -0.7497s;
}
div.spinner div.bar5 {
  -webkit-transform: rotate(200deg) translate(0, -130%);
  -webkit-animation-delay: -0.667s;
  transform: rotate(200deg) translate(0, -130%);
  animation-delay: -0.667s;
}
div.spinner div.bar6 {
  -webkit-transform: rotate(240deg) translate(0, -130%);
  -webkit-animation-delay: -0.5837s;
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.5837s;
}
div.spinner div.bar7 {
  -webkit-transform: rotate(280deg) translate(0, -130%);
  -webkit-animation-delay: -0.5s;
  transform: rotate(280deg) translate(0, -130%);
  animation-delay: -0.5s;
}
div.spinner div.bar8 {
  -webkit-transform: rotate(320deg) translate(0, -130%);
  -webkit-animation-delay: -0.4167s;
  transform: rotate(320deg) translate(0, -130%);
  animation-delay: -0.4167s;
}

div.spinner div.bar9 {
  -webkit-transform: rotate(80deg) translate(0, -130%);
  -webkit-animation-delay: -0.8334s;
  transform: rotate(80deg) translate(0, -130%);
  animation-delay: -0.8334s;
}
