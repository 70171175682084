@import './auth.css';
@import './mui-phone-select.css';
@import './calendar.css';

@font-face {
  font-family: 'Soehne Bold';
  font-weight: 500;
  src: url('assets/fonts/soehne-kraftig.woff2');
}
@font-face {
  font-family: 'Soehne';
  font-weight: 400;
  src: url('assets/fonts/soehne-buch.woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Medium';
  font-weight: 500;
  src: url('assets/fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto Bold';
  font-weight: 700;
  src: url('assets/fonts/Roboto-Bold.ttf');
}

a {
  text-decoration: none;
}
a:focus {
  outline: none;
}
.calendar_icon {
  border-radius: 15px;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.calendar_icon:hover {
  cursor: pointer;
  background-color: var(--joy-palette-primary-plainHoverBg);
}
.css-wxhqmy-JoyModal-backdrop {
  background-color: rgba(19, 19, 19, 0.5);
  backdrop-filter: blur(3px);
}
.shimmer {
  color: #75757c;
  background: -webkit-gradient(linear, left top, right top, from(#75757c), to(#75757c), color-stop(0.5, #d5dbe2));
  background: -moz-gradient(linear, left top, right top, from(#75757c), to(#75757c), color-stop(0.5, #d5dbe2));
  background: gradient(linear, left top, right top, from(#75757c), to(#75757c), color-stop(0.5, #d5dbe2));
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 125px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  animation: shimmer 2s infinite alternate;
  -moz-animation: shimmer 2s infinite alternate;
  -webkit-animation: shimmer 2s infinite alternate;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #75757c;
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
