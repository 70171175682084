.auth-bg {
  position: relative;
}
.auth-bg:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* opacity: 0.15; */
  /* background-image: url('assets/images/auth-bg.svg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover; */
}

@media screen and (max-width: 600px) {
  .auth-width {
    width: 100% !important;
    max-width: 100% !important;
  }
}
