.MuiPickersPopper-paper {
  background: #ffffff;
  border: 1px solid #efeff0;
  border-radius: 8px;
  box-shadow: 2px 6px 20px 6px #a0a0a042;
}

.MuiPickersPopper-root {
  opacity: 0.7;
  backdrop-filter: blur(4px);
  height: 100vh;
  width: 100vw;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiPickersCalendarHeader-root {
  margin-top: 10px;
}

.MuiDayPicker-header {
  border-bottom: 1px solid #f9fafb;
}

.MuiDayPicker-weekContainer {
  margin: 7px 0;
}

.MuiPickersDay-root {
  font-family: 'Soehne';
  font-size: 14px;
  color: #858585;
}

.MuiPickersDay-root:not(.Mui-selected) {
  border-radius: 6px;
  padding: 8px;
  gap: 8px;
}
.MuiPickersDay-root.Mui-disabled {
  color: #c9cfd4;
}

.MuiPickersDay-root:hover,
.MuiPickersDay-root.Mui-selected {
  background: #f6f7fd !important;
  border: 1px solid #e7f1ff;
  border-radius: 6px;
  padding: 8px;
  gap: 8px;
  color: #0a0a11;
}
.MuiPickersDay-root:hover {
  border: 1px solid #f9fafb;
  background: #fff !important;
  color: #858585;
}
.MuiPickersDay-today {
  color: #2477e5;
  border: 1px solid transparent !important;
}
.MuiPickersDay-root.Mui-selected {
  color: #2477e5;
  border: 1px solid transparent !important;
}
.MuiPickersDay-root.Mui-selected {
  color: #2477e5;
}

.PrivatePickersYear-yearButton {
  color: #0b0b0b;
}

.MuiPickersCalendarHeader-label {
  font-size: 16px;
}

/* [data-popper-placement*='top'] > .JoyGrid-container {
  align-items: flex-end;
}

[data-popper-placement*='top'] > .JoyGrid-container > .JoyGrid-root:first-child {
  margin-bottom: -9px;
}

[data-popper-placement*='top'] > .JoyGrid-container > .JoyGrid-root:last-child {
  margin-bottom: -1px;
} */
