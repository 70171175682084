/*
  
section.page:not(.focus):not(:hover)::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-track,::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 999px;
}
::-webkit-scrollbar-track {
  ** box-shadow: 1px 1px 5px rgba(0,0,0,.2) inset; **
}
::-webkit-scrollbar-thumb {
  background: url(/img/noise.png);
  background-clip: content-box;
  ** box-shadow: 0 0 0 5px hsla(24, 20%, 50%,.4) inset; **
  min-height: 20px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
*/

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 5px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: '#9FA6AE';
  -webkit-border-radius: 100px;
  right: '4px';
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: '#9FA6AE';
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars. 
       Yes, I pulled out digital color meter */
  background: rgba(112, 111, 111, 0.5);
  -webkit-border-radius: 100px;
  right: '4px';
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
  right: '4px';
}
